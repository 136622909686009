export const GTMUserIdAndLoginState = () => {
    if(window.dataLayer.push != undefined) {
        if(localStorage.getItem('sessionId') !== '' && localStorage.getItem('sessionId') != null && 
        localStorage.getItem('userID') !== '' && localStorage.getItem('userID') != null){
        window.dataLayer.push({ 
            "event" : "metadata",
            "customerid" : localStorage.getItem('userID'),
            "LoginStatus" : "TRUE"
        });
        }
    }
}

export const GTMUserId= () => {
    if(window.dataLayer.push != undefined) {
        if(localStorage.getItem('userID') !== '' && localStorage.getItem('userID') != null){
        window.dataLayer.push({ 
            "event" : "metadata",
            "customerid" : localStorage.getItem('userID'),
        });
        }
    }
}

export const GTMApplicationId = () => {
    if(window.dataLayer.push != undefined) {
        window.dataLayer.push({
            "event" : "metadata",
            "applicationID" : localStorage.getItem('applicationId')
        });
    }
}

export  const GTMFieldInteraction = (fieldLabel) => {
    if(fieldLabel !== '' && fieldLabel !== undefined){
        window.dataLayer.push({ 
            event: 'field_interaction', 
            form_field_name: fieldLabel, 
            funnel: 'funnel', 
          
        }); 
    }
}

export const GTMClientSubmitErrors = (PageName, validationType, Errors, stepNumber, steps, stepProgress) => {
    window.dataLayer.push({
        event: 'step_submit',
        funnel_type: 'funnel',
        validation_type: validationType,
        origination_step: PageName,
        step_submit_status: 'error',
        step_number: stepNumber,
        steps: steps, 
        step_progress: stepProgress,
        errors: ["There are " + Errors+ " required fields that are either blank or incorrect. Please check again."],
      });
}


export const GTMServerSubmitError = (PageName, validationType, Errors, stepNumber, steps, stepProgress) => {
    window.dataLayer.push({
        event: 'step_submit',
        funnel_type: 'funnel',
        validation_type: validationType,
        origination_step: PageName,
        step_submit_status: 'error',
        step_number: stepNumber,
        steps: steps, 
        step_progress: stepProgress,
        errors: [Errors],
      });
}

export const GTMApplicationSubmitStatus = (PageName, validationType, stepNumber, Steps, stepProgress, ApplicationOutcome) => {

    if(window.dataLayer.push != undefined) {
        window.dataLayer.push({
            event: 'funnel_submit',
            funnel_type: 'funnel',
            validation_type: validationType,
            origination_step: PageName,
            step_submit_status: 'valid',
            step_number : stepNumber,
            steps : Steps,
            step_progress : stepProgress,
            outcome: ApplicationOutcome,
        });
    }
}

export const GTMFieldSubmitErrors = (PageName, validationType, Errors) => {
    if(PageName !== '' && PageName != undefined && Errors !== '' && Errors != undefined) {
        window.dataLayer.push({
            event: 'step_submit', 
            funnel_type: 'funnel', 
            validation_type: validationType, 
            origination_step: PageName,
            step_submit_status: 'error', 
            step_number: 1,
            steps: 1,
            step_progress: '1 : 1',
            errors: [Errors],
        });
    }
}

export const GTMFieldValidChange = (fieldName) => {
    if(fieldName !== '' && fieldName !== undefined){
        if(window.dataLayer != undefined){
            window.dataLayer.push({ 
                event: 'field_change',
                form_field_name: fieldName,
                form_field_status: 'valid',
                funnel: 'funnel',
            });
        }
    }
}

export const GTMFieldErrorChange = (fieldName) => {
    if(fieldName !== '' && fieldName !== undefined){
        if(window.dataLayer != undefined){
            window.dataLayer.push({ 
                event: 'field_change',
                form_field_name: fieldName,
                form_field_status: 'error',
                funnel: 'funnel',
            });
        }
    }
}