import React,{ Component } from "react";
import {forgotPassword} from "../../../action/auth/forgotPassword";
import { Redirect } from 'react-router-dom';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import Reaptcha from 'reaptcha';
import Loader from "../../loader/loader";

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
          email:'',
          disable:'',
          errors:{
            emailError:'',
          },
          redirect:false,
          serverError:'',
          domainMessage:'',
          reCaptchaToken: '',
          captchaReady: false,
          verified: false,
          forgotPasswordFlag: false,
          serverErrorMessage: '',
        };
          this.reCaptchaRef = null;
          this.handleChange = this.handleChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
          this.removeFromHistory = this.removeFromHistory.bind(this);
      }

         
  handleChange(event) {
    
    const target = event.target;
    const value = target.value;
    const name = target.name;
    // analyticsTypeEvent(name.toLocaleLowerCase())
    this.setState({ [name]: value});
    let errors = this.state.errors;
    if(name==="email"){ 
      if (
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@[a-z0-9-]+(\.[a-z]{2,})+$/i.test(
          value
        )
      ) {
        errors.emailError='';
      }else if (value.trim() === "") {
         errors.emailError = "Email is required";
         this.setState({serverError:''})
      }else{
         errors.emailError= "Please enter a valid email";
         this.setState({serverError:''})
      }
    }

    this.setState({errors, [name]: value});
  }

    componentDidMount() {
    
        window.onpopstate = e => {
            this.removeFromHistory();
        }
    }

    onCaptchaLoad = () => {
        this.setState({
          captchaReady: true
        });
    };

    onCaptchaVerify = (getToken) => {

        // If the reCaptcha is loaded and token is generated, save the token to the state reCaptchaToken
        if(getToken != null && getToken !== '' && this.state.captchaReady){
            this.setState({reCaptchaToken: getToken}, () => {
                if(this.state.forgotPasswordFlag){
                    this.handleSubmit();
                }
            });
            this.setState({verified: true})
        }
        else {
            this.setState({verified: false})
        }

    }

    removeFromHistory() {

        if (window.$('#forgotModal').hasClass('show') === false) {
            window.$('#forgotModal').modal('hide');
            window.$('.modal-backdrop').removeClass('show');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
        }
    }

   handleSubmit=async(_event)=> {

        setTimeout(async() => {
            const obj={
                'email':this.state.email,
                "recaptchaToken": this.state.reCaptchaToken,
                'Application_Channel': 'Web'
            }
            localStorage.setItem('forgotEmail',this.state.email)
            this.setState({forgotPasswordFlag: false})
            const result = await forgotPassword(obj);

            if(result.status===200){
                let domainMessage='Verification code send to registered e-mail address '+this.state.email;
                this.setState({domainMessage:domainMessage, serverError: ''}, ()=> {
                    this.setState({loading: false})
                    window.$('#forgotModal').modal('show');
                });
                
            }
            else if(result.data == undefined) {
                this.setState({loading: false, serverError: '', serverErrorMessage: "Something went wrong. Try Again"})
                window.$('#forgotPasswordErrorModal').modal('show');
            }
            else{
                this.setState({loading: false, serverError:result.data.message})
                this.setState({disable:''})
            }
        }, 1000);
    }

    redirectCodeScreen=(e)=>{
        analyticsClickEvent('forgot_password_popup_ok','')
        this.setState({redirect: true});
        this.setState({domainMessage: ''});
         window.$('#forgotModal').modal('hide');
    }

    handleRecaptchaForSubmit = (event) => {

        if (window.$('#forgotPasswordErrorModal').hasClass('show') === true) {
            window.$('#forgotPasswordErrorModal').modal('hide');
        }

        analyticsClickEvent('forgot_password_send_button','')
        event.preventDefault();

        if (this.state.email === '') {
            this.setState({ errors:{...this.state.errors,emailError: 'Email is required' }});
            return false;
        }

        if (this.state.errors.emailError !== '') {
            return false;
        }
        this.setState({disable:'disabled', forgotPasswordFlag: true, recaptchaToken: ''});
        this.setState({loading: true})
        if(this.reCaptchaRef !== null && this.reCaptchaRef !== undefined){
            this.reCaptchaRef.execute();
        }
    }

    backgroundImage=()=>{
            return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
            <div className="leftLogin-img-sec">
                <img src="images/humm-login-bg.jpg" alt="Background" />
            </div>
            </div>;
    }


    render(){
        if (this.state.redirect) {
            return <Redirect push to="/forgot-password-code" />;
        }
        return(
            <div className="layout-fixed">
            <div className="wrapper login-page scroll-sec-fix">
                <Loader loaderStatus={this.state.loading} />
                {this.backgroundImage()}
                <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
                    <div className="section-form">
                        <div className="form-header">
                            <span className="section-logo">
                                <img src="images/logo.svg" alt="logo"/>
                            </span>
                            <span className="section-heading">
                                <h4>Forgot your password? No problem, please enter your email below and we will send you a reset link</h4>
                            </span>
                        </div>
                        <div className="form-body">
                            <form id="login-form" className="log-form" name="loginForm">
                                <div className="error-user">
                                    <div className="form-group floating-label-group">
                                        <input type="email" className="form-control" id="email" name='email'  value={this.state.email} placeholder=" "   required onChange={this.handleChange} onClick={(e)=>analyticsClickEvent('forgot_password_email','')}/>
                                        <label className="floating-label">Email</label>
                                    </div>
                                    <p className={this.state.serverError!== '' ? `error-message mb-4` : `error-message`}> 
                                        {this.state.errors.emailError !== '' &&
					<span style={{ color: 'Red' }}>{this.state.errors.emailError}</span>}
                                        {this.state.serverError && this.state.serverError!=='Verification code sent to registered email' &&
                                        <span style={{color:'Red'}}>{this.state.serverError}</span>}
                                    </p>
                                </div>
                                <Reaptcha
                                        ref={event => (this.reCaptchaRef = event)}
                                        size="invisible"
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        badge="bottomright"
                                        onLoad={this.onCaptchaLoad}
                                        onVerify={this.onCaptchaVerify}
                                />
                                <div className="form-group" id="send-button-group">
                                    <input type="submit" className="btn btn-warning form-control" id="submit" value="Send" onClick={this.handleRecaptchaForSubmit} />
                                </div>  
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.domainMessage &&
                <div className="modal fade model-error" id="forgotModal" tabIndex="-1" aria-labelledby="forgotModalLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid"/>
                                </div>
                                <h5 className="modal-title" id="forgotModalLabel">Email</h5>
                                <p className="mt-4">{this.state.domainMessage}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button type="button" className="btn btn-primary"  onClick={(e)=>this.redirectCodeScreen(e)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="modal fade model-error" id="forgotPasswordErrorModal" tabIndex="-1" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid"/>
                                </div>
                                <p className="mt-4">{this.state.serverErrorMessage}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button type="button" className="btn btn-primary"  onClick={this.handleRecaptchaForSubmit}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

}

export default ForgotPassword;