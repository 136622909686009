import React, { Component } from 'react';
import Input from './InputType/input';
import CheckBox from './InputType/checkbox';
import Select from './InputType/select';
import TextArea from './InputType/textarea';
import Radio from './InputType/radio';
import RunningText from './InputType/runningText';
import FormType from './InputType/formType';
import InputDatePicker from './InputType/inputDatePicker';
import AutoAddress from './InputType/autoAddress';
import { currentAddressFields, PreviousAddressFields } from "../../utils/const";
import { connect } from 'react-redux';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { GTMFieldErrorChange, GTMFieldValidChange } from '../../utils/GtmDatalayer';
import { withRouter } from 'react-router';
import { PropTypes } from 'prop-types';

export class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            fieldList: [],
            errors: {},
            showBlur: {},
            prepopulateUpdate: true,
            hideFieldList: ['Previous_Address_Line1__c','Address_Line1__c','flxPersonMobile__c', 'Postal_Code__c', 'FlatNumber__c', 'HouseName__c', 'HouseNumber__c', 'Street__c',
                'County__c', 'Town__c', 'PreviousFlatNumber__c',
                'PreviousHouseName__c', 'PreviousHouseNumber__c', 'PreviousPostal_Code__c', 'PreviousStreet__c', 'PreviousTown__c', 'Previous_Address_Country__c', 'Address_Line2__c', 'Previous_Address_Line2__c'],
            hasPreviousAddressFieldsActive: false,
            showValidation: this.props.showAppValidations,
            showInformations: '',
            amountFieldName: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.BlurEvent = this.BlurEvent.bind(this);
        this.autoaddressfieldchange = this.autoaddressfieldchange.bind(this);
        this.allowManuallyHandleClick = this.allowManuallyHandleClick.bind(this);
        this.populateAddressBasedOnDate = this.populateAddressBasedOnDate.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.saveApplicationData !== undefined){
            const allDetailDatas = Object.assign({}, ...nextProps.saveApplicationData);
            if (allDetailDatas != prevState.fields && prevState.prepopulateUpdate === true) {
                return {
                    fields: allDetailDatas,
                };
            }

            return null;
        }
    }

    blurEventForLoanAmount = (event) => {
        if (event.target.name === 'genesis__Loan_Amount__c' && event.target.value.length > 1) {
            let fields = this.state.fields;
            let amountValue = event.target.value;

            if ((amountValue.indexOf(".") >= 0)) {
                fields['genesis__Loan_Amount__c'] = amountValue.toLocaleString("en-GB");
                this.setState({ fields: fields });
            }
            else {
                fields['genesis__Loan_Amount__c'] = amountValue.toLocaleString("en-GB") + '.00';
                this.setState({ fields: fields });
            }
        }
    }

    BlurEvent = async (e, message, maximumValue, fieldName, fieldLabel) => {
        this.GTMhandleChange(fieldName, fieldLabel);

        this.setState({ amountFieldName: '' })
        let showBlurField = this.state.showBlur;
        let popupText = message;
        if (window.$('body').hasClass('modal-open') === false) {
            if (e.target.name === 'Total_Monthly_Income__c') {
                const monthlyIncome = e.target.value.split(',').join('');
                let IntegerMonthlyIncome = Number(monthlyIncome);
                if (IntegerMonthlyIncome > maximumValue && showBlurField[e.target.name] === undefined) {
                    IntegerMonthlyIncome = (IntegerMonthlyIncome * 12).toString();
                    if (IntegerMonthlyIncome.length !== 0) {
                        // Given the currency code of UK for the NumberFormat() method to add commas after last 3 places in amount
                        IntegerMonthlyIncome = new Intl.NumberFormat('en-GB').format(IntegerMonthlyIncome);
                    }
                    const positionOf = popupText.indexOf('£');
                    let finalMessage = [popupText.slice(0, positionOf-1),' <b>£',IntegerMonthlyIncome,'</b>', popupText.slice(positionOf+1)].join('');
                    this.setState({ showInformations: finalMessage, amountFieldName: e.target.name })
                    analyticsClickEvent(e.target.name + '_confirm','')
                    document.getElementById("showMessage").innerHTML = finalMessage.toString()
                    window.$('#MonthlyExpModal').appendTo("body").modal('show');
                }
            }
        }

        this.blurEventForLoanAmount(e);

    }


    blurEventForInputFieldOfThirdSection = (fieldName) => {
        if (this.props.applicationValidation) {
            const allInputDetails = Object.assign({}, ...this.props.applicationValidation);

            if (fieldName == 'Address_Line1__c' || fieldName == 'Town__c' ||
                fieldName == 'Postal_Code__c') {
                let stagesValues = {}
                if (allInputDetails[fieldName] === '') {
                    if (this.props.prepopulateStatus) {

                        this.validationforPrepopulatedInAddressSection();
                    } else {
                        stagesValues[fieldName] = true;
                        this.props.thirdSectionValidationUpdate(stagesValues);
                    }
                }
                else {
                    stagesValues[fieldName] = false;
                    this.props.thirdSectionValidationUpdate(stagesValues);
                }
            }
        }
    }

    validationforPrepopulatedInAddressSection = () => {
        const allUserApplicationDetails = Object.assign({}, ...this.props.saveApplicationData);
        let applicationFieldValues = {}, applicationStageThreeValues = {}

        if ((this.props.prepopulateStatus) && allUserApplicationDetails['Address_Line1__c'] !== '' &&
            allUserApplicationDetails['Address_Line1__c'] !== undefined) {
            applicationFieldValues['Address_Line1__c'] = '';
            applicationStageThreeValues['Address_Line1__c'] = true;
        }
        if ((this.props.prepopulateStatus) && allUserApplicationDetails['Postal_Code__c'] !== '' &&
            allUserApplicationDetails['Postal_Code__c'] !== undefined) {
            applicationFieldValues['Postal_Code__c'] = '';
            applicationStageThreeValues['Postal_Code__c'] = true;
        }
        if ((this.props.prepopulateStatus) && allUserApplicationDetails['Town__c'] !== '' &&
            allUserApplicationDetails['Town__c'] !== undefined) {
            applicationFieldValues['Town__c'] = '';
            applicationStageThreeValues['Town__c'] = true;
        }
        if ((this.props.prepopulateStatus) && allUserApplicationDetails['Current_Residential_Status__c']) {

            applicationFieldValues['Current_Residential_Status__c'] = '';
            applicationStageThreeValues['Current_Residential_Status__c'] = true;
        }

        this.props.appvalidations(applicationFieldValues);
        this.props.thirdSectionValidationUpdate(applicationStageThreeValues);
    }

    blurEventForInputFieldOfFourthSection = (fieldName) => {
        if(this.props.applicationValidation) {
            const allInputDetails = Object.assign({}, ...this.props.applicationValidation);

            let stagesValues={}
            
            if(fieldName == 'Fractional_Residential_Expense__c' ||
            fieldName == 'monthly_expense_financial_commitments'|| 
            fieldName == 'Fractional_Living_Expense__c' ) {
                
                if(allInputDetails[fieldName] === '') {
                    stagesValues[fieldName] = true;
                }
                else {
                    stagesValues[fieldName] = false;
                }

                this.props.fourSectionValidationUpdate(stagesValues);
            }

        }

    }

    blurEventForInputFieldForAllSections = (fieldName) => {
        setTimeout(() => {
            if(fieldName == 'Address_Line1__c' || fieldName == 'Town__c' ||
            fieldName == 'Postal_Code__c') {
                this.blurEventForInputFieldOfThirdSection(fieldName)
            }
            if(fieldName == 'Total_Monthly_Income__c' || fieldName == 'Employment_Stability__c' ||
            fieldName == 'occupation' || fieldName == 'flxNumber_of_Other_Financial_Dependents__c' || 
            fieldName == 'Fractional_Residential_Expense__c' ||  fieldName == 'monthly_expense_financial_commitments'
            || fieldName == 'Fractional_Living_Expense__c' || fieldName == 'Total_Monthly_Expenses__c') {
                this.blurEventForInputFieldOfFourthSection(fieldName)
            }

        }, 900);
    }

    GTMhandleChange(field, fieldLabel){
        switch(field){
            case "First_Name":
            case "Last_Name":
            case "Mobile_Number":
            case "Email_Address":
            case "flxFirst_Name__c":
            case "flxLast_Name__c":
            case "genesis__Loan_Amount__c":
            case "frequency":
            case "Loan_Term_Requested__c":
            case "Current_Address_Country__c":
            case "Address_Line1__c":
            case "Address_Line2__c":
            case "Town__c":
            case "Postal_Code__c":
            case "Current_Residential_Status__c":
            case "Previous_Address_Country__c":
            case "Previous_Address_Line1__c":
            case "Previous_Address_Line2__c":
            case "PreviousTown__c":
            case "PreviousPostal_Code__c":
            case "Total_Monthly_Income__c":
            case "Fractional_Residential_Expense__c":
            case "monthly_expense_financial_commitments":
            case "Fractional_Living_Expense__c":
            case "Total_Monthly_Expenses__c":
            case "flxNumber_of_Other_Financial_Dependents__c":
            case "Employment_Stability__c":
            case "occupation":
            case "sortcode_bankaccount":
            case "genesis__bank_account_number__c":
                const {applicationValidation } = this.props;
                if (applicationValidation.some(value => value.hasOwnProperty(field))) {
                    const entry = applicationValidation.find(value => value[field] !== undefined && value[field] !== "");
                    if (entry) {
                        GTMFieldErrorChange(fieldLabel);
                    } else {
                        GTMFieldValidChange(fieldLabel);
                    }
                }
                break;
        }
    }

    handleChangeForExpenditureFieldIfPart = (field, fields, allDetailDatas, value) => {
        if (field === 'Fractional_Residential_Expense__c') {
            if (allDetailDatas['Current_Residential_Status__c'] === 'Homeowner - mortgaged' || allDetailDatas['Current_Residential_Status__c'] === 'Tenant/Boarding') {
                if (value === '0') {
                    fields['Fractional_Residential_Expense__c'] = '';
                    this.setState({ fields: fields });
                    return false;
                }
            }
        }
        if (field === 'Current_Residential_Status__c') {
            if (allDetailDatas['Fractional_Residential_Expense__c'] !== undefined && allDetailDatas['Fractional_Residential_Expense__c'] !== '' && allDetailDatas['Fractional_Residential_Expense__c'].charAt(0) == 0) {
                fields['Total_Expenditure__c'] = allDetailDatas['Total_Expenditure__c'] - allDetailDatas['Fractional_Residential_Expense__c'];
                this.setState({ fields: fields });
                return false;
            }
        }
    }

    handleChangeForExpenditureField = (field, fields, allDetailDatas, value) => {

        this.handleChangeForExpenditureFieldIfPart(field, fields, allDetailDatas, value)
        
        if (field === 'Fractional_Residential_Expense__c' || field === 'monthly_expense_financial_commitments' || field === 'Fractional_Living_Expense__c' || field === 'Current_Residential_Status__c') {
            let Fractional_Residential_Expense__c = allDetailDatas['Fractional_Residential_Expense__c'];
            if (field === 'Fractional_Residential_Expense__c') {
                Fractional_Residential_Expense__c = value.replace(/\D/g, "").split(',').join('');
            }

            let monthly_expense_financial_commitments = allDetailDatas['monthly_expense_financial_commitments'];
            if (field === 'monthly_expense_financial_commitments') {
                monthly_expense_financial_commitments = value.replace(/\D/g, "").split(',').join('');
            }

            let Fractional_Living_Expense__c = allDetailDatas['Fractional_Living_Expense__c'];
            if (field === 'Fractional_Living_Expense__c') {
                Fractional_Living_Expense__c = value.replace(/\D/g, "").split(',').join('');
            }
            let finalValue = ''

            const finalExpenditureResult = this.getFinalExpenditureValue(Fractional_Residential_Expense__c, monthly_expense_financial_commitments, Fractional_Living_Expense__c, field, value);

            let final_Fractional_Residential_Expense__c = finalExpenditureResult['final_Fractional_Residential_Expense__c']
            let final_monthly_expense_financial_commitments = finalExpenditureResult['final_monthly_expense_financial_commitments']
            let final_Fractional_Living_Expense__c = finalExpenditureResult['final_Fractional_Living_Expense__c']

            finalValue = final_Fractional_Residential_Expense__c + final_monthly_expense_financial_commitments + final_Fractional_Living_Expense__c;
            let StoreVales = {};
            StoreVales['Total_Expenditure__c'] = finalValue.toString();
            this.props.saveApplicationDatas(StoreVales);
            this.props.saveExpenditureDatas(StoreVales['Total_Expenditure__c']);
            fields['Total_Expenditure__c'] = finalValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.setState({ fields: fields });

        }
    }

    getFinalExpenditureValue = (Fractional_Residential_Expense__c, monthly_expense_financial_commitments, Fractional_Living_Expense__c, field, value) => {
        let final_Fractional_Residential_Expense__c = 0, final_monthly_expense_financial_commitments = 0, final_Fractional_Living_Expense__c = 0;;
        let allDetailsOfExpenditure = {}

        if (Fractional_Residential_Expense__c != undefined && Fractional_Residential_Expense__c != '') {
            final_Fractional_Residential_Expense__c = parseInt(Fractional_Residential_Expense__c);
        }

        if (monthly_expense_financial_commitments != undefined && monthly_expense_financial_commitments != '') {
            final_monthly_expense_financial_commitments = parseInt(monthly_expense_financial_commitments);
        }

        if (Fractional_Living_Expense__c != undefined && Fractional_Living_Expense__c != '') {
            final_Fractional_Living_Expense__c = parseInt(Fractional_Living_Expense__c);
        }

        if(field==='Current_Residential_Status__c' && (value === 'Homeowner - no mortgage' || value === 'Living with parents/family' )){
            final_Fractional_Residential_Expense__c=0;
        }

        allDetailsOfExpenditure['final_Fractional_Residential_Expense__c'] = final_Fractional_Residential_Expense__c
        allDetailsOfExpenditure['final_monthly_expense_financial_commitments'] = final_monthly_expense_financial_commitments
        allDetailsOfExpenditure['final_Fractional_Living_Expense__c'] = final_Fractional_Living_Expense__c

        return allDetailsOfExpenditure;
    }

    handleChangeEmailCheck = (field, value) => {
        if (field === 'flxPersonConfirmEmail__c' || field === 'flxPersonEmail__c') {
            if (value.length > 50) {
                return false;
            }
        }
    }

    handleChangeForCommonInputFields = (field, value) => {

        this.handleChangeEmailCheck(field, value)

        if (new Set(['genesis__bank_account_number__c', 'flxNumber_of_Other_Financial_Dependents__c']).has(field)) {
            if (!/\d$/.test(value)) {
                return false;
            }
        }

        if (field === 'flxFirst_Name__c' || field === 'occupation' || field === 'First_Name') {
            if (!/^[A-Za-z ]+$/.test(value)) {
                return false;
            }
        }

        if (field === 'flxLast_Name__c' || field === 'Last_Name') {
            if (!/[a-zA-Z '-]+$/.test(value)) {
                return false;
            }
        }

        if (field === 'flxPersonEmail__c' || field === 'Email_Address') {
            if (!/^.{1,50}$/.test(value)) {
                let fields = this.state.fields;
                fields[field] = value
                this.setState({ fields: fields });

                return false;
            }
        }

        this.handleChangeforMobileNumber(field, value)
    }

    handleChangeforMobileNumber = (field, value) => {
        if (field === 'flxPersonMobile__c') {
            if (value.length === 12) {
                return false;
            }
        }
    }

    handleChangeForLoanAmountField = (field, value) => {
        if (field === 'genesis__Loan_Amount__c' || field === 'Total_Monthly_Income__c' || 
            field === 'Fractional_Residential_Expense__c' || field === 'monthly_expense_financial_commitments' ||
            field === 'Fractional_Living_Expense__c') {
            if (value.length !== 0) {
                if (value.indexOf(".") >= 0) {

                    // get position of first decimal
                    // this prevents multiple decimals from
                    // being entered
                    let decimal_pos = value.indexOf(".");

                    // split number by decimal point
                    let left_side = value.substring(0, decimal_pos);
                    let right_side = value.substring(decimal_pos);

                    // add commas to left side of number
                    left_side = this.formatNumber(left_side);

                    // validate right side
                    right_side = this.formatNumber(right_side);


                    // Limit decimal to only 2 digits
                    right_side = right_side.substring(0, 2);
                    // join number by .
                    value = left_side + "." + right_side;

                } else {
                    value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
        }

        return value;
    }

    assignValueForLoanAmount = (field, fields, value) => {
        if (field === 'genesis__Loan_Amount__c') {
            if (value.charAt(0) == 0) {
                if(value > 0){
                  return false;  
                }
                fields['genesis__Loan_Amount__c'] = '';
                this.setState({ fields: fields });
                return false;
            }
        }
    }

    sortCodeValueCheck = (value) => {
        if (!/^-*\d[- 0-9]*$/.test(value)) {
            return false;
        }
        if (value.length == 6) {
            value = value.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3");
        } 
        if (value.length >= 2) {
            value = value.replace(/(\d{2})(\d{2})/, "$1-$2");
        }
        if (value.length !== 0) {
            value = value.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3");
        }

        return value
    }

    handleChangeForBankAccountField = (field, value) => {
        if (field === 'genesis__bank_account_number__c') {
            if (value.length === 9) {
                return false;
            }
        }
    }

    async handleChange(field, value, type = '', label = '') {

        if(field==='date_moved_in_current_address'){
            this.props.showAddress('');
        }
        let fields = this.state.fields;
        const allDetailDatas = Object.assign({}, ...this.props.saveApplicationData);

        // function call for assigning Loan amount
        this.assignValueForLoanAmount(field, fields, value);         

        // function call for calculating total expenditure for LT and BT things
        this.handleChangeForExpenditureField(field, fields, allDetailDatas, value);

        this.setState({ prepopulateUpdate: false })

        if (field === 'sortcode_bankaccount') {

            if (value.length >= 9) {
                return false;
            }

            // function call for input field - sort code 
            value = this.sortCodeValueCheck(value);

            fields['sortcode_bankaccount'] = value
            this.setState({fields: fields});
        }

        // function call for input field - bank account number
        this.handleChangeForBankAccountField(field, value)

        if (field === 'Postal_Code__c' || field === 'PreviousPostal_Code__c') {
            value = value.toUpperCase()
            if (value.length >= 9) {
                return false;
            }
            if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
                return false;
            }
        }


        if (type === 'Currency' && field != 'genesis__Loan_Amount__c') {
            if (value.length !== 0) {
                value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        }

        // function call for "genesis__Loan_Amount__c" input field
        value = this.handleChangeForLoanAmountField(field, value)

        // function call for input fields
        this.handleChangeForCommonInputFields(field, value)

        fields[field] = value;
        this.setState({ fields: fields });

        if (field === 'date_moved_in_current_address') {
            let hasShowPreviousAddress = this.monthDiff(value);
            this.populateAddressBasedOnDate(hasShowPreviousAddress);

        }

        this.blurEventForInputFieldForAllSections(field);
    }
    //compare the given month from current month
    monthDiff(d1) {
        if (d1 === null) {
            return 0;
        }
        let today = new Date();
        let months;
        months = (today.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += today.getMonth() + 1;
        return months <= 0 ? 0 : months;
    }

    //formattocurrency
    formatNumber = (n) => {
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }



    populateAddressBasedOnDate(hasShowPreviousAddress) {
        const allDetailDatas = Object.assign({}, ...this.props.saveApplicationData);
        const hideFieldList = this.state.hideFieldList;
        let fields = this.state.fields;
        let errors = this.state.errors;
        let ValidationFields = ['PreviousStreet__c', 'PreviousTown__c', 'PreviousPostal_Code__c']
        if (hasShowPreviousAddress <= 5 && this.state.fields['flxBirthDate__c'] !== undefined && allDetailDatas['date_moved_in_current_address']!='') {
            let lessHideFieldList = this.state.hideFieldList.filter(function (ele) {
                return ele !== 'Previous_Address_Country__c';
            });
            this.setState({
                hideFieldList: lessHideFieldList
            });
        } else if (hasShowPreviousAddress > 5) {
            ValidationFields.forEach((src) => {
                let stages = {}
                errors[src] = '';
                stages[src] = '';
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
            });
            this.setState({
                errors
            });
            if (!hideFieldList.includes('Previous_Address_Country__c')) {
                hideFieldList.push("Previous_Address_Country__c");
                this.setState({
                    hideFieldList
                });

            }

            if (this.state.hasPreviousAddressFieldsActive) {
                PreviousAddressFields.forEach((previousAddressField) => {
                    hideFieldList.push(previousAddressField);
                    if (fields[previousAddressField]) {
                        fields[previousAddressField] = undefined;
                    }
                });


            }
            this.setState({
                hideFieldList,
                fields, hasPreviousAddressFieldsActive: false
            });
        }
    }


    autoaddressfieldchange(values) {
        let fields = this.state.fields;
        let hidefields = this.state.hideFieldList;
        Object.keys(values).forEach(key => {
            fields[key] = values[key];
            hidefields = this.arrayRemove(hidefields, key);
            if (values[key] === 'PreviousCurrent_Address_Country__c') {
                this.setState({ hasPreviousAddressFieldsActive: true })
            }

        });
        this.setState({
            hideFieldList: hidefields,
            fields: fields
        }, () => {
            this.render();
        });

    }

    arrayRemove(arr, value) {

        return arr.filter(function (ele) {
            return ele !== value;
        });
    }

    analyticsConfirmClickEvent = (data) => {
// analyticsClickEvent(this.state.amountFieldName +'_confirm','')
analyticsClickEvent('confirm','')


    }




    allowManuallyHandleClick(field) {
        let hideFieldList = this.state.hideFieldList
        switch (field) {
            case 'Current_Address_Country__c':
                hideFieldList = hideFieldList.filter(val => !currentAddressFields.includes(val));
                this.setState({
                    hideFieldList
                });
                break;

            case 'Previous_Address_Country__c':
            {
                let preHideFieldList = this.state.hideFieldList.filter(val => !PreviousAddressFields.includes(val));
                this.setState({
                    hideFieldList: preHideFieldList,
                    hasPreviousAddressFieldsActive: true
                });
                break;
            }
        }

        if(field==='both'){
                hideFieldList = hideFieldList.filter(val => !currentAddressFields.includes(val));
                this.setState({
                    hideFieldList
                });
                 hideFieldList = hideFieldList.filter(val => !PreviousAddressFields.includes(val));
                this.setState({
                    hideFieldList,
                    hasPreviousAddressFieldsActive: true
                });
        }
    }


    displayInputFields = (field, prepopulateCondition) => {
        switch (field.flxType__c) {

            case 'Text':
            case 'Currency':
            case 'Email':
            case 'Number':
            case 'Phone':
            case 'ip called':
                return (
                    <Input
                        key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_question={field.flxLabelPriorQuestion__c}
                        field_placeholder={field.flxPlaceHolder__c}
                        field_value={this.state.fields[field.flxFieldApiName__c] === undefined ? '' : this.state.fields[field.flxFieldApiName__c]}
                        field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                        field_type={field.flxType__c}
                        field_questions={field.Questions}
                        field_fieldinfo={field.field_description}
                        field_answers={field.answers}
                        field_api_name={field.flxFieldApiName__c}
                        field_api_validations={field.validations}
                        threshold={field.threshold_limit}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        phoneNumber={this.props.phoneNumber}
                        fieldChange={this.handleChange}
                        fieldBlur={this.handleBlur}
                        priority={field.flxPriority__c}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        onBlurEvent={this.BlurEvent}
                        flxQuestionMoreInfo={field.flxQuestionMoreInfo__c}
                        goBackAmendClicked={this.props.goBackAmendClicked}
                    />
                );

            case 'Auto Address':
                return (
                    <AutoAddress key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_question={field.flxLabelPriorQuestion__c}
                        field_placeholder={field.flxPlaceHolder__c}
                        field_value={field.ans_Name}
                        field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                        field_type={field.flxType__c}
                        field_questions={field.Questions}
                        field_fieldinfo={field.field_description}
                        field_answers={field.answers}
                        field_api_name={field.flxFieldApiName__c}
                        fieldValidation={field.validations}
                        addressChange={this.autoaddressfieldchange}
                        allowManually={this.allowManuallyHandleClick}
                        fieldChange={this.handleChange}
                        priority={field.flxPriority__c}
                        stage={this.props.stage}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        flxQuestionMoreInfo={field.flxQuestionMoreInfo__c}
                    />
                );

            case 'Date':
            case 'Card expiry date':
                return (<InputDatePicker
                    key={field.id}
                    field_id={field.hummuk_flxCmsQuestion__c_id}
                    field_label={field.flxLabelPriorQuestion__c}
                    field_placeholder={field.flxPlaceHolder__c}
                    field_question={field.flxLabelPriorQuestion__c}
                    field_value={field.ans_Name}
                    field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                    field_type={field.hummuk_flxCmsQuestion__c_flxType__c}
                    field_answers={field.answers}
                    field_questions={field.Questions}
                    field_fieldinfo={field.field_description}
                    field_api_name={field.flxFieldApiName__c}
                    field_api_validations={field.validations}
                    showValidation={this.props.showValidation}
                    stage={this.props.stage}
                    prepopulateCondition={prepopulateCondition}
                    priority={field.flxPriority__c}
                    editable={field.editable}
                    fieldChange={this.handleChange} 
                    initialLoad={this.state.prepopulateUpdate}
                    flxQuestionMoreInfo={field.flxQuestionMoreInfo__c}
                    />);


            case 'Picklist':
            case 'select box called':
                return (
                    <Select
                        key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_type={field.flxType__c}
                        field_options={field.answers}
                        field_value={''}
                        field_questions={field.Questions}
                        field_fieldinfo={field.field_description}
                        field_api_name={field.flxFieldApiName__c}
                        field_api_validations={field.validations}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        priority={field.flxPriority__c}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        fieldChange={this.handleChange}
                        flxQuestionMoreInfo={field.flxQuestionMoreInfo__c}
                    />
                );

            case 'Checkbox':
                return (
                    <CheckBox
                        key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_question={field.flxLabelPriorQuestion__c}
                        field_placeholder={field.flxPlaceHolder__c}
                        field_value={field.ans_Name}
                        field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                        field_type={field.hummuk_flxCmsQuestion__c_flxType__c}
                        field_questions={field.Questions}
                        field_fieldinfo={field.field_description}
                        field_answers={field.answers}
                        field_api_name={field.flxFieldApiName__c}
                        field_api_validations={field.validations}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        priority={field.flxPriority__c}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        fieldChange={this.handleChange}
                    />
                );

            case 'Text area':
                return (
                    <TextArea
                        key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_type={field.flxType__c}
                        field_value={field.answers}
                        field_questions={field.Questions}
                        field_fieldinfo={field.field_description}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        priority={field.flxPriority__c}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        fieldChange={this.handleChange}
                    />
                );

            case 'Radio':
            case 'radio button called':
                return (
                    <Radio
                        key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_type={field.flxType__c}
                        field_value={field.flxDefaultAnswer__c}
                        field_options={field.answers}
                        field_checked={field.flxDefaultAnswer__c}
                        field_questions={field.Questions}
                        field_fieldinfo={field.field_description}
                        field_header={field.flxHeader__c}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        priority={field.flxPriority__c}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        fieldChange={this.handleChange}
                    />
                );

            case 'Running Text':
            case 'Read Only':

                return (<RunningText
                    key={field.id}
                    field_id={field.hummuk_flxCmsQuestion__c_id}
                    field_label={field.flxLabelPriorQuestion__c}
                    field_type={field.flxType__c}
                    field_value={field.answers}
                    field_questions={field.Questions}
                    field_fieldinfo={field.field_description}
                    stage={this.props.stage}
                    showValidation={this.props.showValidation}
                    priority={field.flxPriority__c}
                    prepopulateCondition={prepopulateCondition}
                    editable={field.editable}
                    initialLoad={this.state.prepopulateUpdate}
                    fieldChange={this.handleChange}
                />);
            case 'Form':
            case '':
                return (<FormType
                    key={field.id}
                    field_value={field.flxSectionBodyRich__c}
                    field_questions={field.Questions}
                    field_fieldinfo={field.field_description}
                    stage={this.props.stage}
                    showValidation={this.props.showValidation}
                    priority={field.flxPriority__c}
                    fieldChange={this.handleChange}
                    prepopulateCondition={prepopulateCondition}
                    editable={field.editable}
                    initialLoad={this.state.prepopulateUpdate}
                />);


            default:
                return (
                    <p></p>
                );

        }
    }

    getDisplayFields(field) {
        let prepopulateCondition = field.pre_populated;
        if (this.props.prepopulateStatus === true && field.not_for_existing === true) {
            return {};
        }


        if (this.state.hideFieldList.indexOf(field.flxFieldApiName__c) === -1) {
            return this.displayInputFields(field, prepopulateCondition)
        }
    }
    render() {
        let field = '';
        if (this.props.fieldItem !== '' && this.props.fieldItem  !== undefined) {
            field = this.props.fieldItem.sort((a, b) => {
                return a.flxSequence__c - b.flxSequence__c;
            });

        }
        return (
            <React.Fragment>
                {field !== '' &&
                    field.map((question) =>
                        this.getDisplayFields(question)
                    )

                }
                {this.state.showInformations != '' &&
                    <div className="modal fade model-error application-infomation-modal" id="MonthlyExpModal" tabIndex="-1"  aria-labelledby="MonthlyExpModalLabel" aria-hidden="true">
                        <div className="modal-dialog" >
                            <div className="modal-content">
                                <div className="modal-body pb-0 mt-2">
                                    <div className="logo mb-4">
                                        <img src="images/modal-logo.svg" alt="logo" className="img-fluid mb-2" />
                                        <h5 className='font-weight-bold'>Note</h5>
                                    </div>
                                    <p className="mt-2 mb-3" id="showMessage"></p>
                                </div>
                                <div className="modal-footer mb-3">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.analyticsConfirmClickEvent()}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }



            </React.Fragment>

        );




    }
}

export const mapStateToProps = (state) => {
    return {
        applicationValidation: state.dataStore.applicationValidation,
        applicationValidationOnBlur: state.dataStore.applicationValidationOnBlur,
        loaderStatus: state.dataStore.loaderStatus,
        showAppValidations: state.dataStore.showAppValidations,
        saveApplicationData: state.dataStore.saveApplicationData,
        prepopulateStatus: state.dataStore.prepopulateStatus,
        showCurrentAddress: state.dataStore.showCurrentAddress,
        firstSectionSteps: state.dataStore.firstSectionSteps,
        secondSectionSteps: state.dataStore.secondSectionSteps,
        thirdSectionSteps: state.dataStore.thirdSectionSteps,
        fourSectionSteps: state.dataStore.fourSectionSteps,
        fifthSectionSteps: state.dataStore.fifthSectionSteps,
        amountRequested: state.dataStore.amountRequested,
        threashold: state.dataStore.threashold
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        appvalidations: (validations) => {
            return dispatch({
                type: 'APP_VALIDATIONS',
                value: validations
            });
        },
        appValidationsBlur: (validations) => {
            return dispatch({
              type: "APP_VALIDATIONS_FOR_BLUR",
              value: validations
            });
        },
        firstSectionValidationUpdate: (validations) => {
            return dispatch({
              type: "FIRST_SECTION_STEPS",
              value: validations
            });
        },
        saveApplicationDatas: (data) => {
            return dispatch({
                type: 'SAVEDATA',
                value: data
            });
        },
        saveExpenditureDatas: (data) => {
            return dispatch({
                type: 'SAVEEXPENDITUREDATA',
                value: data
            });
        },
        loaderStatusUpdate: (validations) => {
            return dispatch({
                type: 'LOADER_STATUS',
                value: validations
            });
        },
        thirdSectionValidationUpdate: (validations) => {
            return dispatch({
              type: "THIRD_SECTION_STEPS",
              value: validations
            });
        },
        fourSectionValidationUpdate: (validations) => {
            return dispatch({
              type: "FOUR_SECTION_STEPS",
              value: validations
            });
        },
        showAddress:(data) => {
            return dispatch({
              type: 'SHOWADDRESS',
              value: data
            });
          },

    }
}

Element.propTypes = {
    showAppValidations: PropTypes.string,
    saveApplicationData: PropTypes.array,
    applicationValidation: PropTypes.array,
    thirdSectionValidationUpdate: PropTypes.func,
    fourSectionValidationUpdate: PropTypes.func,
    saveApplicationDatas: PropTypes.func,
    appvalidations: PropTypes.func,
    stage: PropTypes.string,
    prepopulateStatus: PropTypes.bool,
    showValidation: PropTypes.string,
    fieldItem: PropTypes.array,
    saveExpenditureDatas: PropTypes.func,
    showAddress: PropTypes.func,
    phoneNumber: PropTypes.any,
    goBackAmendClicked:PropTypes.bool

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Element));